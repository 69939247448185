
(async () => {
  const calendarElement = document.getElementById("calendar");
  const calendar = window.jsCalendar.new({
    target: calendarElement,
    monthFormat: "month YYYY",
    language: "pl",
    navigatorPosition: "right",
    dayFormat: "DDD",
    firstDayOfTheWeek: "2"
  });

  setupCalendarLang();
  calendar.setLanguage("pl");

  const maxResults = 2500; // maximum 2500, default 250
  const currentYear = (new Date()).getFullYear();
  const startYear = currentYear - 1; // year when starts searching for events
  const timeMin = `${startYear}-01-01T00%3A00%3A00%2B02%3A00`;
  const key = "AIzaSyBNlYH01_9Hc5S1J9vuFmu2nUqBZJNAXxs";
  const calendarId = "a790fda6ec6faaf2e3d3fd377c331ae17a64130c9a98f9029408b84de13f2054@group.calendar.google.com";
  const apiUrl = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?calendarId=${calendarId}&maxResults=${maxResults}&timeMin=${timeMin}&key=${key}`;
  const request = await fetch(apiUrl);
  const response = await request.json();

  const dates = response?.items
    ?.flatMap(({ start, end }) =>
      getDates(new Date(start.date), new Date(end.date))
    )
    .map(
      (date) => `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
    );

  calendar.select(dates);

  /*
   * Utils functions below
   */

  function addDays(currentDate, days) {
    var date = new Date(currentDate.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

  function getDates(startDate, stopDate) {
    var dateArray = [];
    var currentDate = startDate;
    while (currentDate < stopDate) {
      dateArray.push(new Date(currentDate));
      currentDate = addDays(currentDate, 1);
    }
    return dateArray;
  }

  /*
   * jsCalendar language extension
   * Add Polish Language support
   */
  function setupCalendarLang() {
    var jsCalendar = window.jsCalendar;

    // Add a new language
    jsCalendar.addLanguage({
      code: "pl",
      months: [
        "Styczeń",
        "Luty",
        "Marzec",
        "Kwiecień",
        "Maj",
        "Czerwiec",
        "Lipiec",
        "Sierpień",
        "Wrzesień",
        "Październik",
        "Listopad",
        "Grudzień"
      ],
      days: [
        "Niedziela",
        "Poniedziałek",
        "Wtorek",
        "Środa",
        "Czwartek",
        "Piątek",
        "Sobota"
      ]
    });
  }
})();
